import { useParams } from "@reach/router"
import React, { Fragment } from "react"
import { CheckCircleIcon, ExclamationIcon } from "@heroicons/react/solid"
import PageLoading from "../PageLoading"
import {
  useGetSummaryCheckInOutDailyByBuildingQuery,
  GetCheckInOutDailyByBuildingComponent,
  GetCheckInOutDailyByBuildingQuery,
} from "../../generated/graphql"
import { DateUtils } from "../../libs/date"
import { format } from "date-fns"

const CONTEXT = {
  headers: {
    authorization: null,
    "x-hasura-role": "anonymous",
  },
}

export const BuildingPage = () => {
  const { buildingId } = useParams()
  const { data, loading } = useGetSummaryCheckInOutDailyByBuildingQuery({
    variables: {
      buildingId: buildingId,
    },
    pollInterval: 2000,
    ssr: false,
    context: CONTEXT,
  })

  if (!data?.rows && loading) {
    return <PageLoading />
  }

  const row = data?.rows?.[0]

  const currentCount = row
    ? Math.max(row.check_in_count - row.check_out_count, 0)
    : 0

  return (
    <div>
      <div className="p-10">
        <h3 className="text-4xl leading-6 font-medium text-gray-900">
          {row?.building ? (
            <Fragment>
              {row?.building.name}{" "}
              <span className="text-lg">({row?.building.id})</span>
            </Fragment>
          ) : null}
        </h3>
        <dl className="mt-10 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <div className="flex flex-col overflow-hidden">
            <div className="flex-grow px-4 py-5 sm:p-6">
              <div className="flex items-center flex-col">
                <div className="">
                  <dt className="text-3xl font-medium text-orange-500 leading-2 text-center">
                    Current
                  </dt>
                  <dd className="flex items-baseline justify-center">
                    <div className="text-7xl font-semibold text-orange-900">
                      {currentCount}
                    </div>
                  </dd>
                </div>
                <div>
                  <img
                    alt={row?.building?.name || "QR Code"}
                    src={row?.building?.qrcode || ""}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col overflow-hidden shadow rounded-lg col-span-2 h-5/h">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden">
                  <GetCheckInOutDailyByBuildingComponent
                    variables={{ buildingId }}
                    pollInterval={2000}
                    ssr={false}
                    context={CONTEXT}
                  >
                    {({ data }) => (
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-lg font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Time
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-lg font-medium text-gray-500 uppercase tracking-wider"
                            >
                              In/Out
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-lg font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-lg font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Vaccined
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-lg font-medium text-gray-500 uppercase tracking-wider"
                            >
                              ATK (วว/ดด)
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {data?.rows.map((row) => (
                            <tr key={row.id}>
                              <td className="px-6 py-4 whitespace-nowrap text-lg font-medium text-gray-900">
                                {DateUtils.toTime(row.timestamp, "HH:mm:ss")}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-lg font-semibold	">
                                {row.type === "in" ? (
                                  <span className="text-green-700">
                                    Check In
                                  </span>
                                ) : (
                                  <span className="text-red-700">
                                    Check Out
                                  </span>
                                )}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-lg font-medium text-gray-500">
                                {row.contact_name}
                              </td>
                              {/* VACCINED */}
                              <td className="px-6 py-4 whitespace-nowrap text-lg font-medium text-gray-500">
                                {(() => {
                                  if (!row?.user)
                                    return (
                                      <ExclamationIcon
                                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-orange-300"
                                        aria-hidden="true"
                                      />
                                    )
                                  const vaccined = extractVaccinedFromUser(
                                    row.user
                                  )

                                  if (!vaccined)
                                    return (
                                      <ExclamationIcon
                                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-orange-300"
                                        aria-hidden="true"
                                      />
                                    )

                                  return (
                                    <div className="flex items-center">
                                      <CheckCircleIcon
                                        className="flex-shrink-0 mr-1.5 h-7 w-7 text-green-700"
                                        aria-hidden="true"
                                      />

                                      <div className="font-medium text-green-800">
                                        {vaccined} เข็ม
                                      </div>
                                    </div>
                                  )
                                })()}
                              </td>
                              {/* ATK */}
                              <td className="px-6 py-4 whitespace-nowrap text-lg font-medium text-gray-500">
                                {(() => {
                                  if (!row?.user?.atk_results?.length)
                                    return "-"
                                  const atk = row.user.atk_results?.[0]

                                  if (atk.infected) {
                                    return (
                                      <div className="flex items-center">
                                        <ExclamationIcon
                                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-700"
                                          aria-hidden="true"
                                        />
                                        <span className="text-red-700">
                                          {format(new Date(atk.date), "dd/MM")}
                                        </span>
                                      </div>
                                    )
                                  }

                                  return (
                                    <div className="flex items-center">
                                      <CheckCircleIcon
                                        className="flex-shrink-0 mr-1.5 h-7 w-7 text-green-700"
                                        aria-hidden="true"
                                      />
                                      <span className="font-medium text-green-700">
                                        {format(new Date(atk.date), "dd/MM")}
                                      </span>
                                    </div>
                                  )
                                })()}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </GetCheckInOutDailyByBuildingComponent>
                </div>
              </div>
            </div>
          </div>
        </dl>
      </div>
    </div>
  )
}

function extractVaccinedFromUser(
  user: GetCheckInOutDailyByBuildingQuery["rows"][number]["user"]
): number | undefined {
  if (!user?.vaccined_information?.vaccined?.[0]) {
    return undefined
  }

  const vaccined = user.vaccined_information.vaccined[0]

  if (vaccined.vaccined_6_date) {
    return 6
  }

  if (vaccined.vaccined_5_date) {
    return 5
  }

  if (vaccined.vaccined_4_date) {
    return 4
  }

  if (vaccined.vaccined_3_date) {
    return 3
  }

  if (vaccined.vaccined_2_date) {
    return 2
  }

  if (vaccined.vaccined_1_date) {
    return 1
  }

  return undefined
}
