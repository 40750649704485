import React, { useCallback } from "react"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { AuthForm } from "../components/auth"
import KmitlLogo from "../images/logo-go.png"
import { SWAL_FOOTER } from "../constants"

export function useSwalAuth() {
  const MySwal = withReactContent(Swal)

  const fire = useCallback(() => {
    return MySwal.fire({
      title: (
        <img
          className="xl:inline block h-16 w-auto"
          src={KmitlLogo}
          alt="KMITL"
        />
      ),
      footer: SWAL_FOOTER,
      allowEscapeKey: false,
      allowOutsideClick: false,
      showCancelButton: false,
      backdrop: true,
      html: (
        <AuthForm
          onCompleted={() => {
            MySwal.close()
          }}
        />
      ),
      showCloseButton: false,
      showConfirmButton: false,
      showDenyButton: false,
      customClass: {
        content: "auth-content",
      },
    })
  }, [MySwal])

  return {
    Swal,
    fire,
  }
}
