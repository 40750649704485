import React, { Fragment } from "react"
import { useLocation } from "@reach/router"
import qs from "query-string"
import {
  BuildingFragment,
  useGetBuildingByPkQuery,
} from "../../generated/graphql"
import NotFound from "../NotFound"
import { CheckInOut } from "../check"
import PageLoading from "../PageLoading"
import AlertToLogin from "../auth/AlertToLogin"
import { useSession } from "../../hooks/useSession"

export type EnterQuery = {
  timestamp: string
  id: string
  sign: string
}

export const EnterBuildingPage = () => {
  const location = useLocation()
  const { isAuthenticated } = useSession()
  const { id } = qs.parse(location.search) as EnterQuery
  const { data, loading } = useGetBuildingByPkQuery({
    variables: {
      id: id,
    },
    ssr: false,
  })

  if (loading) {
    return <PageLoading />
  }

  if (!data?.row) {
    return <NotFound />
  }

  return (
    <Fragment>
      {!isAuthenticated && <AlertToLogin />}
      <CheckInOut building={data.row as BuildingFragment} />
    </Fragment>
  )
}
