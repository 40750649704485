import { MutationResult } from "@apollo/client"
import { useCallback, useMemo } from "react"
import {
  CheckInBuildingByPkMutation,
  CheckInBuildingByPkMutationVariables,
  useCheckInBuildingByPkMutation,
} from "../generated/graphql"
import { useSession } from "./useSession"

type UseCheckInMutationResult = [
  (args: { variables?: CheckInBuildingByPkMutationVariables }) => void,
  MutationResult<CheckInBuildingByPkMutation>
]

export function useCheckInMutation(): UseCheckInMutationResult {
  const { anonymous } = useSession()

  // หากเป็น anonymous จะต้อง force headers
  const headers: Record<string, string> = useMemo(
    () => ({
      ...(anonymous
        ? {
            "X-Hasura-Tel": anonymous.tel,
          }
        : {}),
    }),
    [anonymous]
  )

  const [checkInFn, response] = useCheckInBuildingByPkMutation()

  const mutationFn = useCallback(
    (args: { variables?: CheckInBuildingByPkMutationVariables }) => {
      checkInFn({
        ...args,
        context: {
          headers,
        },
      })
    },
    [checkInFn, headers]
  )

  return [mutationFn, response]
}
