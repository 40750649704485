import React from "react"

export type CheckButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

export const CheckOutButton = (props: CheckButtonProps) => {
  return (
    <button
      type="button"
      className="inline-flex w-5/11 items-center px-4 py-3 md:px-6 md:py-5 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
      {...props}
    >
      <svg
        className="ml-1 md:mr-3 h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
          clipRule="evenodd"
        />
      </svg>
      <div className="text-lg md:text-2xl leading-3 font-medium">
        เช็คเอ้าท์
      </div>
    </button>
  )
}
