import { navigate } from "gatsby"
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import VaccineList from "../vaccine/VaccineList"
import { SWAL_FOOTER } from "../../constants"
import { BuildingFragment, TraceTypeEnum } from "../../generated/graphql"
import { useSession } from "../../hooks/useSession"
import { useVaccine } from "../../hooks/useVaccine"
import { DateUtils } from "../../libs/date"
import { CheckInButton } from "./CheckInButton"
import { CheckOutButton } from "./CheckOutButton"
import { useCheckInMutation } from "../../hooks/useCheckInMutation"
import { useCheckOutMutation } from "../../hooks/useCheckOutMutation"

export type CheckProps = { building: BuildingFragment }

/**
 * แสดง Popup หลังจากที่ Check In / Out เสร็จสิ้น
 * @param checkType TraceTypeEnum.In หรือ TraceTypeEnum.Out
 * @param building ข้อมูลตึก
 * @returns
 */
const CheckDone = ({
  checkType,
  building,
}: {
  checkType: TraceTypeEnum
  building?: BuildingFragment
}) => {
  const { type, user, anonymous } = useSession()
  const {
    data: { vaccines },
    loading,
  } = useVaccine()

  useEffect(() => {
    if (loading) return

    const hasVaccine = vaccines.length > 0

    const MySwal = withReactContent(Swal)

    // ถ้าเป็นบุคลากรสถานะ และได้รับวัคซีนแล้ว จะแสดงติ๊กถูก นอกนั้น warning
    const icon = type === "kmitl" && hasVaccine ? "success" : "warning"

    MySwal.fire({
      confirmButtonText: "เสร็จสิ้น",
      showConfirmButton: true,
      icon,
      footer: SWAL_FOOTER,
      allowEscapeKey: false,
      allowOutsideClick: false,
      showCancelButton: false,
      customClass: "w-full",
      backdrop: true,
      didClose: () => {
        navigate("/")
      },
      title: (
        <span className="text-gray-700 text-xl font-medium">
          คุณ {type === "kmitl" ? user?.display_name : anonymous?.contact_name}
        </span>
      ),
      html: (
        <Fragment>
          <div className="text-emerald text-lg font-medium">
            {checkType === TraceTypeEnum.In
              ? "Check In แล้ว"
              : "Check Out แล้ว"}
          </div>
          <div className="text-lg font-medium">
            วันที่ {DateUtils.thaiDateShort(new Date())} เวลา{" "}
            {DateUtils.toTime(new Date())} น. <br />
            อาคาร {building?.name}
          </div>

          {type === "kmitl" ? <VaccineList vaccines={vaccines} /> : null}
          {type === "anonymous" ? (
            <h6 className="text-xl font-medium text-red-700 text-center">
              บุคคลภายนอกสถาบัน
            </h6>
          ) : null}
        </Fragment>
      ),
    })
  }, [
    anonymous?.contact_name,
    building?.name,
    checkType,
    loading,
    type,
    user?.display_name,
    vaccines,
  ])

  return <div />
}

export const CheckInOut = ({ building }: CheckProps) => {
  const [checkType, setCheckType] = useState<TraceTypeEnum | null>(null)
  const { anonymous } = useSession()

  const variables = useMemo(
    () => ({
      buildingId: building.id,
      email: anonymous?.email,
      contact_name: anonymous?.contact_name,
    }),
    [anonymous?.contact_name, anonymous?.email, building.id]
  )

  const [checkInFn] = useCheckInMutation()
  const [checkOutFn] = useCheckOutMutation()

  const handleCheckIn = useCallback(() => {
    checkInFn({
      variables,
    })
    setCheckType(TraceTypeEnum.In)
  }, [variables, checkInFn])

  const handleCheckOut = useCallback(() => {
    checkOutFn({
      variables,
    })
    setCheckType(TraceTypeEnum.Out)
  }, [checkOutFn, variables])

  return (
    <div>
      <h5 className="leading-7 font-normal text-2xl mb-4">
        สถานที่: {building.name}
      </h5>
      <div className="">
        <span className="flex md:justify-evenly justify-between">
          <CheckInButton onClick={handleCheckIn} />
          <CheckOutButton onClick={handleCheckOut} />
        </span>
      </div>
      {checkType ? (
        <CheckDone checkType={checkType} building={building} />
      ) : null}
    </div>
  )
}
