import { MutationResult } from "@apollo/client"
import { useCallback, useMemo } from "react"
import {
  CheckOutBuildingByPkMutation,
  CheckOutBuildingByPkMutationVariables,
  useCheckOutBuildingByPkMutation,
} from "../generated/graphql"
import { useSession } from "./useSession"

type UseCheckOutMutationResult = [
  (args: { variables?: CheckOutBuildingByPkMutationVariables }) => void,
  MutationResult<CheckOutBuildingByPkMutation>
]

export function useCheckOutMutation(): UseCheckOutMutationResult {
  const { anonymous } = useSession()

  // หากเป็น anonymous จะต้อง force headers
  const headers: Record<string, string> = useMemo(
    () => ({
      ...(anonymous
        ? {
            "X-Hasura-Tel": anonymous.tel,
          }
        : {}),
    }),
    [anonymous]
  )

  const [checkOutFn, response] = useCheckOutBuildingByPkMutation()

  const mutationFn = useCallback(
    (args: { variables: CheckOutBuildingByPkMutationVariables }) => {
      checkOutFn({
        ...args,
        context: {
          headers,
        },
      })
    },
    [checkOutFn, headers]
  )

  return [mutationFn, response]
}
