import React, { Fragment } from "react"
import { withPrefix } from "gatsby"
import { Router, RouteComponentProps } from "@reach/router"
import Layout from "../../components/layout/Layout"
import { EnterBuildingPage } from "../../components/enter-building"
import { BuildingPage } from "../../components/building"
import { Helmet } from "react-helmet"

type WithLayoutProps = Record<string, unknown>

const withLayout =
  (Component: React.ComponentType) => (props: WithLayoutProps) => {
    return (
      <Layout>
        <Component {...props} />
      </Layout>
    )
  }

const EnterBuildingWithLayout = withLayout(EnterBuildingPage)

const App = () => {
  return (
    <Fragment>
      <Helmet>
        <title>GoFight | KMITL</title>
      </Helmet>
      <Router>
        <RouterPage
          path={withPrefix("/app/enter")}
          pageComponent={<EnterBuildingWithLayout />}
        />
        <RouterPage
          path={withPrefix("/app/building/:buildingId")}
          pageComponent={<BuildingPage />}
        />
      </Router>
    </Fragment>
  )
}

const RouterPage = (
  props: { pageComponent: JSX.Element } & RouteComponentProps
) => props.pageComponent

export default App
