import React, { useEffect } from "react"
import { useSwalAuth } from "../../hooks/useSwalAuth"

/**
 * @description
 * Force ให้แสดงหน้าจอ Popup Login ไว้ใช้กับหน้าใดๆ ที่ต้องการดัก Login ก่อน โดยไม่ให้ redirect
 *
 * @returns
 */
const AlertToLogin = () => {
  const { fire } = useSwalAuth()

  useEffect(() => {
    fire()
  }, [fire])

  return <span id="alert-to-login" />
}

export default AlertToLogin
